import { mdiCheck, mdiPageLast, mdiPageNext } from "@mdi/js";
import preguntasModule from "@/store/modules/preguntasModule";
import { Paso, DetalleArticulo, Articulo } from "apd.sos.faq.models";
import Vue from "vue";
import { GoToOptions } from "vuetify/types/services/goto";

export default Vue.extend({
  name: "InfoArticuloComponent",
  props: {
    articulo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        check: mdiCheck,
        next: mdiPageNext,
        back: mdiPageLast,
      } as Record<string, string>,
      e1: 1,
      pasos: [] as Paso[],
      idDetalle: 1,
      detalle: [] as DetalleArticulo[],
      options: { duration: 1000, offset: 20, easing: "easeInOutCubic" } as GoToOptions,
    };
  },
  computed: {
    pregunta(): Articulo {
      return preguntasModule.ArticuloById(this.articulo.id);
    },
    target(): string {
      const element = "#id" + this.e1;
      return element;
    },
  },
  methods: {
    async siguiente(id: number, n: number) {
      this.idDetalle = id;
      this.detalle = await this.articulo.detalleArticulo;
      let d = this.detalle.find((o) => o.id == id);
      if (d?.paso != undefined) {
        this.pasos = d?.paso;
      }

      if (n >= this.pasos[this.pasos.length - 1].posicion) {
        d = this.detalle.find((o) => o.id == id);

        if (
          this.articulo.detalleArticulo.length > 1 &&
          this.idDetalle != this.articulo.detalleArticulo[this.articulo.detalleArticulo.length - 1].id
        ) {
          this.e1 = n + 1;
        } else {
          this.e1 = 1;
        }
        setTimeout(() => {
          this.$vuetify.goTo(this.target, this.options);
        }, 400);
      } else {
        this.e1 = n + 1;
        setTimeout(() => {
          this.$vuetify.goTo(this.target, this.options);
        }, 400);
      }
    },
    async goTo(id: number) {
      this.idDetalle = id;
      setTimeout(() => {
        this.$vuetify.goTo(this.target, this.options);
      }, 400);
    },
    anterior(id: number, n: number) {
      this.idDetalle = id;
      this.detalle = this.articulo.detalleArticulo;
      const d = this.detalle.find((o) => o.id == id);
      if (d?.paso != undefined) {
        this.pasos = d?.paso;
      }
      if (n == 1) {
        this.e1 = this.pasos.length;
        setTimeout(() => {
          this.$vuetify.goTo(this.target, this.options);
        }, 400);
      } else {
        this.e1 = n - 1;
        setTimeout(() => {
          this.$vuetify.goTo(this.target, this.options);
        }, 400);
      }
    },
  },
});

import { render, staticRenderFns } from "./infoArticulo.vue?vue&type=template&id=510c1dcd&class=mt-0%20pt-0"
import script from "./infoArticulo.ts?vue&type=script&lang=ts&external"
export * from "./infoArticulo.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports